const links = [
    {
        hidden: 'xs',
        label: "legal.copyright",
        to: "#"
    },
    {
        label: "legal.policy",
        to: "https://www.tropipay.com/privacy"
    },
    {
        label: "legal.terms",
        to: "https://www.tropipay.com/terms"
    }
]

export default links;