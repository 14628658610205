const currency = {
    list: () => {
        return [{
                label: "EUR",
                value: "EUR",
            },
            {
                label: "USD",
                value: "USD",
            }
        ]
    }
};
export default currency;